import React, { lazy, Component } from "react";
import { NavLink } from "react-router-dom";
import Spinner from "../UI/Spinner/Spinner";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./Header.scss";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      //isMenuOpen: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: false });
  }
  handleToggle = () => {
    document.body.classList.toggle('menuOpen');
    //this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };
  CloseNav = () => {
    document.querySelector(".hamburger").click();
  };
  CloseProfile = () => {
    document.querySelector(".dropdown-toggle").click();
  };

  scrollToBrand = () => {
    document.getElementById("brand").click();
  };

  render() {
    //const isMenuOpen = this.state.isMenuOpen;
    return (
      <>
        {this.state.loading === true ? <Spinner /> : null}
        <header>
          <Navbar>
            <Container fluid="lg" >
            <div className="menuOverlay" onClick={this.handleToggle}></div>
            <div className="hamburger" onClick={this.handleToggle}><div></div></div>
              <Navbar.Brand href="#home">Al-Raihaan Logo</Navbar.Brand>
              <Nav className="me-md-auto ms-md-auto">
                <NavLink
                  to="/home"
                  onClick={() => this.CloseNav()}
                  className="nav-link"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/room"
                  onClick={() => this.CloseNav()}
                  className="nav-link"
                >
                  Rooms
                </NavLink>
                <NavLink
                  to="/explore"
                  onClick={() => this.CloseNav()}
                  className="nav-link"
                >
                  Explore
                </NavLink>             
                <NavLink
                  to="/about"
                  onClick={() => this.CloseNav()}
                  className="nav-link"
                >
                  About
                </NavLink>
                <NavLink
                  to="/contact"
                  onClick={() => this.CloseNav()}
                  className="nav-link"
                >
                  Contact
                </NavLink>
              </Nav>
              <div className="loginAndUser d-flex align-items-center">
              {localStorage.token !== "" &&
              localStorage.token !== undefined ? null : (
                <a
                  className="btn btn-primary"
                  // href="http://localhost:3000/#/onelogin?SID=t0vnwuesgme0lkl4j1yosobo&amp;Lan=en&amp;App=ITSOnelogin&amp;API=3.0&amp;Token=j7XK3hm7G17y%2fWefwAlYwY%2f%2bQCXXaFjeKw9pVbReZCU%3d&amp;DT=gexXmHiHKQvKqg%2fbK1WOpZG6KinyG2NDFMg6W%2fFrUzWdQbHmwqdhJKK8m8ycN5xn5mlsOjOWINkp5DLrMv2eaCpZvdLTxxPaKaqPee4PNN3r%2b%2fK2la7cBfGMU4Y%2f8zSHYsZUINIxmFgq8vFBDggiZ%2bVqZ%2bDuwuIzLfT5JBtyzvr8%2bOCK1hxxYUTT%2biD3tywKNq9cmyVaX%2fSkOPlfHoUNSqV%2bSrQD60GcX0jg2zBhrM0uotUFmg6c%2ft%2bcuBLCVBR1"
                  href="https://alraihaan.babjisoftech.com/#/onelogin?SID=t0vnwuesgme0lkl4j1yosobo&amp;Lan=en&amp;App=ITSOnelogin&amp;API=3.0&amp;Token=j7XK3hm7G17y%2fWefwAlYwY%2f%2bQCXXaFjeKw9pVbReZCU%3d&amp;DT=gexXmHiHKQvKqg%2fbK1WOpZG6KinyG2NDFMg6W%2fFrUzWdQbHmwqdhJKK8m8ycN5xn5mlsOjOWINkp5DLrMv2eaCpZvdLTxxPaKaqPee4PNN3r%2b%2fK2la7cBfGMU4Y%2f8zSHYsZUINIxmFgq8vFBDggiZ%2bVqZ%2bDuwuIzLfT5JBtyzvr8%2bOCK1hxxYUTT%2biD3tywKNq9cmyVaX%2fSkOPlfHoUNSqV%2bSrQD60GcX0jg2zBhrM0uotUFmg6c%2ft%2bcuBLCVBR1"
               
                >
                  Login
                </a>
              )}
              {localStorage.token !== "" && localStorage.token !== undefined ? (
                <>
                  <div className="favorites">
                    <NavLink
                      to="/favourites"
                      onClick={() => this.CloseNav()}
                      className="nav-link"
                    >
                      Favourites
                    </NavLink>
                  </div>
                  <Dropdown align="end">
                    <Dropdown.Toggle>
                      <Image
                        className=""
                        src={
                          "data:image/png;base64," +
                          localStorage.getItem("photo")
                        }
                        onError={(e) =>
                          (e.target.src = require("../../styles/images/user.png"))
                        }
                        alt={2}
                        // onClick={() => this.toggleLogout()}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <span
                        className="dropdown-item"
                      >
                        {localStorage.getItem('username')}
                      </span>
                      <NavLink
                        to="/profile"
                        className="dropdown-item"
                        onClick={() => this.CloseProfile()}
                      >
                        My Booking
                      </NavLink>
                      {/* <NavLink
                        to="/profile"
                        className="dropdown-item"
                      >
                        Profile
                      </NavLink>
                      <NavLink
                        to="/roomtype"
                        className="dropdown-item"
                      >
                        Roomtype
                      </NavLink> */}
                      <NavLink
                        to="/logout"
                        className="dropdown-item"
                      >
                        Logout
                      </NavLink>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : null}
              </div>
            </Container>
          </Navbar>
         
        </header>

        {/* <NavLink
          to="/home"
          spy={true}
          smooth={true}
          onClick={() => this.CloseNav()}
          style={{ textDecoration: "none" }}
        >
          Home
        </NavLink>
        <br></br>
        <NavLink
          to="/room"
          spy={true}
          smooth={true}
          onClick={() => this.CloseNav()}
          style={{ textDecoration: "none" }}
        >
          Rooms
        </NavLink>
        <br></br>
        <NavLink
          to="/explore"
          spy={true}
          smooth={true}
          onClick={() => this.CloseNav()}
          style={{ textDecoration: "none" }}
        >
          Explore
        </NavLink>
        <br></br>
        <NavLink
          to="/about"
          spy={true}
          smooth={true}
          onClick={() => this.CloseNav()}
          style={{ textDecoration: "none" }}
        >
          About
        </NavLink>
        <br></br>
        <NavLink
          to="/contact"
          spy={true}
          smooth={true}
          onClick={() => this.CloseNav()}
          style={{ textDecoration: "none" }}
        >
          Contact
        </NavLink>
        <br></br>
        {localStorage.token !== "" &&
          localStorage.token !== undefined ? null : (
          <a
            href="http://localhost:3000/#/onelogin?SID=t0vnwuesgme0lkl4j1yosobo&amp;Lan=en&amp;App=ITSOnelogin&amp;API=3.0&amp;Token=j7XK3hm7G17y%2fWefwAlYwY%2f%2bQCXXaFjeKw9pVbReZCU%3d&amp;DT=gexXmHiHKQvKqg%2fbK1WOpZG6KinyG2NDFMg6W%2fFrUzWdQbHmwqdhJKK8m8ycN5xn5mlsOjOWINkp5DLrMv2eaCpZvdLTxxPaKaqPee4PNN3r%2b%2fK2la7cBfGMU4Y%2f8zSHYsZUINIxmFgq8vFBDggiZ%2bVqZ%2bDuwuIzLfT5JBtyzvr8%2bOCK1hxxYUTT%2biD3tywKNq9cmyVaX%2fSkOPlfHoUNSqV%2bSrQD60GcX0jg2zBhrM0uotUFmg6c%2ft%2bcuBLCVBR1"
            target="_blank"
            rel="noopener noreferrer"
            className="logout"
            style={{ display: "block" }}
          >
            Login &nbsp;&nbsp;
            <i className="pi pi-sign-in" style={{ fontSize: "18px" }}></i>
          </a>
        )}
        <br></br>
        {localStorage.token !== "" && localStorage.token !== undefined ? (
          <NavLink
            className="logout"
            to="/logout"
            onClick={() => this.CloseNav()}
          >
            LOGOUT
          </NavLink>
        ) : null} */}
      </>
    );
  }
}

export default Header;
