import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import homeImg from "../../styles/images/icons/homeIcon.svg";
import bookingImg from "../../styles/images/icons/bookingIcon.svg";
import roomsImg from "../../styles/images/icons/roomsIcon.svg";
import accountImg from "../../styles/images/icons/accoountIcon.svg";
import homeImgActive from "../../styles/images/icons/homeIcon-active.svg";
import bookingImgActive from "../../styles/images/icons/bookingIcon-active.svg";
import roomsImgActive from "../../styles/images/icons/roomsIcon-active.svg";
import accountImgActive from "../../styles/images/icons/accoountIcon-active.svg";
import "./BottomMobileMenu.scss";

class BottomMobileMenu extends Component {
  render() {
    return (
      <section className="show-mobile bottomMenu">
        <Nav className="justify-content-between h-100" activeKey="/home">
          <Nav.Item>
            <NavLink
              to="/home"
              className="d-flex justify-content-center align-items-center flex-column nav-link"
            >
              <Image src={homeImg} className="img-fluid" />
              <span>Home</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              to="/room"
              className="d-flex justify-content-center align-items-center flex-column nav-link"
            >
              <Image src={roomsImg} className="img-fluid" />
              <span>Rooms</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              to="/profile"
              className="d-flex justify-content-center align-items-center flex-column nav-link"
            >
              <Image src={bookingImg} className="img-fluid" />
              <span>My Bookings</span>
            </NavLink>
          </Nav.Item>

          {/* <Nav.Item>
            <NavLink
              to="/profile"
              className="d-flex justify-content-center align-items-center flex-column nav-link"
            >
              <Image src={accountImg} className="img-fluid" />
              <span>Account</span>
            </NavLink>
          </Nav.Item> */}
        </Nav>
      </section>
    );
  }
}

export default BottomMobileMenu;
